<script>
	import { createEventDispatcher } from 'svelte';
	export let options = [];
	export let value = '';
	export let label = 'Select';
	export let width = '';

	const dispatch = createEventDispatcher();
	let isOpen = false;

	// Find the selected option based on the value passed
	$: selectedOption = options.find((option) => option.value === value) || options[0];

	function toggleDropdown() {
		isOpen = !isOpen;
	}

	function selectOption(option) {
		selectedOption = option;
		isOpen = false;
		value = option.value;
		dispatch('change', value);
	}
</script>

<div class={width}>
	<label for="listbox" id="listbox-label" class="block text-sm font-medium leading-6 text-gray-900">
		{label}
	</label>
	<div class="relative mt-2">
		<button
			type="button"
			class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-primary sm:text-sm sm:leading-6"
			aria-haspopup="listbox"
			aria-expanded={isOpen}
			aria-labelledby="listbox-label"
			on:click={toggleDropdown}
			id="listbox"
		>
			<span class="block truncate">{selectedOption.label}</span>
			<span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
				<svg
					class="h-5 w-5 text-gray-400"
					viewBox="0 0 20 20"
					fill="currentColor"
					aria-hidden="true"
				>
					<path
						fill-rule="evenodd"
						d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
						clip-rule="evenodd"
					/>
				</svg>
			</span>
		</button>

		{#if isOpen}
			<ul
				class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
				tabindex="-1"
				role="listbox"
				aria-labelledby="listbox-label"
			>
				{#each options as option, index (option.value)}
					<!-- svelte-ignore a11y-role-has-required-aria-props -->
					<!-- svelte-ignore a11y-click-events-have-key-events -->
					<li
						class="relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900 hover:bg-primary hover:text-white"
						id="listbox-option-{index}"
						role="option"
						on:click={() => selectOption(option)}
					>
						<span
							class="block truncate {option.value === selectedOption.value
								? 'font-semibold'
								: 'font-normal'}">{option.label}</span
						>
						{#if option.value === selectedOption.value}
							<span class="absolute inset-y-0 left-0 flex items-center pl-1.5 text-primary">
								<svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
									<path
										fill-rule="evenodd"
										d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
										clip-rule="evenodd"
									/>
								</svg>
							</span>
						{/if}
					</li>
				{/each}
			</ul>
		{/if}
	</div>
</div>
