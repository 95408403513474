import { API_TOKEN, API_KEY, API_BASE_URL } from './credentials.js';

// Utility function to build query parameters
function buildQueryParams(params) {
	return Object.keys(params)
		.map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
		.join('&');
}

export async function fetchData(endpoint, queryParams = {}, method = 'GET', body = null) {
	const queryString = `${endpoint}?${buildQueryParams(queryParams)}`;
	const url = `${API_BASE_URL}${queryString}`;
	
	// Options de la requête HTTP
	const options = {
		method,
		headers: {
			'Content-Type': 'application/json',
			ApiKey: API_KEY,
			Authorization: `Bearer ${API_TOKEN}`,
			'Cache-Control': 'no-cache', // Désactiver le cache navigateur
			Pragma: 'no-cache' // Désactiver le cache HTTP 1.0
		},
		credentials: 'include' // Ajouter si des cookies ou sessions sont utilisés
	};

	if (body) {
		options.body = JSON.stringify(body);
	}

	
	try {
		// Exécuter la requête
		const response = await fetch(url, options);
		const paginationInfo = response.headers.get('x-pagination');
		const data = await response.json();
		const result = {
			data,
			pagination: paginationInfo ? JSON.parse(paginationInfo) : null
		};

		

		return result;
	} catch (error) {
		console.error('Error fetching data:', error);
		throw error;
	}
}


function isEmpty(value) {
	return (
		value === '' ||
		value === null ||
		value === undefined ||
		(typeof value === 'number' && value === 0) ||
		(typeof value === 'object' && Object.keys(value).length === 0)
	);
}

// Nettoyer les objets imbriqués vides et les valeurs vides dans un objet donné
// Utiliser pour nettoyer les objets avant de les envoyer à l'API
export function cleanObject(obj, seen = new WeakSet()) {
	if (seen.has(obj)) return obj; // Détection de référence circulaire
	seen.add(obj);

	for (const key in obj) {
		const value = obj[key];

		if (typeof value === 'object' && value !== null) {
			if (Array.isArray(value)) {
				// Optimisation : fusion de map et filter
				obj[key] = value.reduce((acc, item) => {
					const cleanedItem = typeof item === 'object' ? cleanObject(item, seen) : item;
					if (!isEmpty(cleanedItem)) {
						acc.push(cleanedItem);
					}
					return acc;
				}, []);

				if (obj[key].length === 0) {
					delete obj[key];
				}
			} else {
				obj[key] = cleanObject(value, seen);

				if (isEmpty(obj[key])) {
					delete obj[key];
				}
			}
		} else if (isEmpty(value)) {
			delete obj[key];
		}
	}

	seen.delete(obj); // Nettoyer le WeakSet pour éviter les fuites mémoire
	return obj;
}
