<script>
	// COMPONENTS
	import Input from '../Input/Input.svelte';
	import NumberInput from '../Input/NumberInput.svelte';
	import DoubleInput from '../Input/DoubleInput.svelte';
	import ButtonPrimary from '../Button/ButtonPrimary.svelte';
	import Steps from '../../components/Steps.svelte';
	// DOCUMENT VENTE
	import { newDevis, newBonCommande } from '../../API/API_SAGE/DocVenteQueries.js';
	import DocumentVenteCreationModel from '../../API/API_SAGE/model/DocumentVenteCreationModel.js';
	// SVELTE
	import { onMount } from 'svelte';
	// STORE
	import { addToast, docVenteTypes } from '../../store.js';

	// VARIABLES
	export let docType = '';

	// MODELS

	$: docVenteBody = new DocumentVenteCreationModel();

	// DOC LIGNES
	let DOCL_RefArticle = '';
	let DOCL_Qte = 1;
	let DOCL_PU = '';

	const steps = [
		{ href: '#', label: 'En-tête' },
		{ href: '#', label: 'Ligne' },
		{ href: '#', label: 'Récapitulatif' }
	];
	$: currentStep = 0;

	// FUNCTIONS
	onMount(() => {
		docVenteBody.ListeRefArticle = [];
	});

	async function createDocVente(event) {
		event.preventDefault();
		let response;
		try {
			if (docType === docVenteTypes.DEVIS) response = await newDevis(docVenteBody).data;
			if (docType === docVenteTypes.COMMANDE) response = await newBonCommande(docVenteBody).data;

			console.log(response);
			

			if (response.status === 200) {
				addToast(response.detail, 'bg-green-500');
			} else {
				addToast(response.detail, 'bg-red-500');
			}
		} catch (error) {
			addToast('Erreur innatendue lors de la création du document de vente', 'bg-red-500');
		}
	}

	function addDocLigne() {
		docVenteBody = {
			...docVenteBody,
			ListeRefArticle: [
				...docVenteBody.ListeRefArticle,
				{
					Reference: DOCL_RefArticle,
					Quantite: DOCL_Qte,
					PrixUnitaire: DOCL_PU
				}
			]
		};

		DOCL_RefArticle = '';
		DOCL_PU = '';
		DOCL_Qte = 1;
	}

	function removeDocLigne(index) {
		docVenteBody = {
			...docVenteBody,
			ListeRefArticle: docVenteBody.ListeRefArticle.filter((_, i) => i !== index)
		};
	}
</script>

<div class="flex flex-col justify-between pb-4 h-full">
	<div class="flex flex-col space-y-1.5 p-6">
		<h1 class="whitespace-nowrap text-2xl font-semibold leading-none tracking-tight">
			Création d'un document de vente - {docType}
		</h1>
	</div>

	<div class="h-max overflow-y-auto">
		<!-- Formulaire pour l'entête -->
		<form on:submit={createDocVente}>
			{#if currentStep === 0}
				<div class="p-6 space-y-4">
					<div class="grid grid-cols-2 gap-4">
						<div class="space-y-2">
							<Input
								id="CT_Num"
								placeholder="BAGUES"
								label="N° client"
								bind:value={docVenteBody.CtNum}
								required minlength="2" maxlength="10"
							/>
						</div>
						<div class="space-y-2">
							<Input
								id="status"
								placeholder="Status"
								label="Status"
								bind:value={docVenteBody.Status}
								required minlength="3" maxlength="15"
							/>
						</div>
					</div>

					<div class="grid grid-cols-2 gap-4">
						<div class="space-y-2">
							<Input
								id="reference"
								placeholder="Référence"
								label="Référence"
								bind:value={docVenteBody.Reference}
								required minlength="3" maxlength="15"
							/>
						</div>
					</div>
				</div>
			{/if}

			<!-- Formulaire pour les lignes -->
			{#if currentStep === 1}
				<div class="p-6 space-y-4">
					<div class="grid grid-cols-2 gap-4">
						<div class="space-y-2">
							<Input
								id="AR_Ref"
								placeholder="BAAR01"
								label="Référence article"
								bind:value={DOCL_RefArticle}
								required minlength="3" maxlength="10"
							/>
						</div>
						<div class="space-y-2">
							<DoubleInput 
								id="pu" 
								placeholder="10.00" 
								label="Prix unitaire" 
								bind:value={DOCL_PU} 
								type="number" min="0.01" step="0.01"
								required
							/>
						</div>
					</div>

					<div class="grid grid-cols-2 gap-4">
						<div class="space-y-2">
							<NumberInput 
								id="qte" 
								placeholder="1" 
								label="Quantité" 
								bind:value={DOCL_Qte} 
								type="number" min="1" required
							/>
						</div>
					</div>

					<div>
						<ButtonPrimary on:click={addDocLigne} type="button">Ajouter la ligne</ButtonPrimary>
					</div>

					<!-- Liste des lignes ajoutées -->
					<div class="mt-6">
						{#if docVenteBody.ListeRefArticle.length > 0}
							<table class="min-w-full divide-y divide-gray-200 border border-gray-200 rounded-lg">
								<thead class="bg-gray-50">
									<tr>
										<th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Référence</th>
										<th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Quantité</th>
										<th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Prix Unitaire</th>
										<th class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
									</tr>
								</thead>
								<tbody class="bg-white divide-y divide-gray-200">
									{#each docVenteBody.ListeRefArticle as ligne, index}
										<tr>
											<td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{ligne.Reference}</td>
											<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{ligne.Quantite}</td>
											<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{ligne.PrixUnitaire} €</td>
											<td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
												<button class="text-red-600 hover:text-red-900" on:click={() => removeDocLigne(index)}>Supprimer</button>
											</td>
										</tr>
									{/each}
								</tbody>
							</table>
						{:else}
							<p class="text-gray-500">Aucune ligne ajoutée pour le moment.</p>
						{/if}
					</div>
				</div>
			{/if}

			<!-- Formulaire pour le récapitulatif -->
			{#if currentStep === 2}
				<div class="flex items-center w-full justify-center">
					<ButtonPrimary px="px-6" py="py-2" text="text-lg" type="submit">Créer</ButtonPrimary>
				</div>
			{/if}
		</form>
	</div>

	<div class="flex flex-col gap-4 pl-4 pr-4">
		<div class="flex justify-between mt-8">
			<button class="flex items-center justify-center w-12 h-12 bg-primary text-white rounded-full shadow-lg hover:bg-primaryDark transition duration-300 ease-in-out" on:click={() => currentStep--} disabled={currentStep === 0}>
				<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
					<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"/>
				</svg>
			</button>
			<button class="flex items-center justify-center w-12 h-12 bg-primary text-white rounded-full shadow-lg hover:bg-primaryDark transition duration-300 ease-in-out" on:click={() => currentStep++} disabled={currentStep === steps.length - 1}>
				<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
					<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"/>
				</svg>
			</button>
		</div>

		<Steps {steps} {currentStep} />
	</div>
</div>
