<script>
	// STOREs
	import { writable } from 'svelte/store';
	import { addToast, debounce } from '../store';

	//SVELTE
	import { onMount } from 'svelte';
	import { createEventDispatcher } from 'svelte';

	// IMPORT COMPONENTS
	import Select from './Select/Select.svelte';
	import LoadingDots from './Loading/LoadingDots.svelte';
	import Tooltip from './Tooltip/Tooltip.svelte';

	// EXPORT VARIABLES
	export let getQuery; // Function to fetch data
	/*
(
	strictSearchFields,
	strictSearchValues,
	excludeSearchFields,
	excludeSearchValues,
	searchFields,
	searchValues,
	OrderDirection,
	pageNumber = 1,
	pageSize = 10,
	OrderBy
)
*/

	export let columns = [];
	export let rowsPerPage = 1000;

	// CONSTANTS
	const pageRowOptions = [
		{ value: 10, label: '10' },
		{ value: 25, label: '25' },
		{ value: 50, label: '50' },
		{ value: 100, label: '100' }
	];
	const groupByOptions = [
		{ value: '', label: 'Aucun' },
		...columns.map((col) => ({ value: col.key, label: col.title }))
	];
	const dispatch = createEventDispatcher();

	// VARIABLES
	let data = [];

	let currentPage = 1;
	let totalPages;
	let hasNext;
	let hasPrevious;

	let searchKey = []; // Pour garder la trace des colonnes de recherche
	let searchValue = []; // Pour garder la trace des valeurs de recherche de chaque colonne
	let selectedColumns = writable(columns.map((col) => col.key));
	let groupByColumn = null;
	let sortOrder = writable('ASC'); // Pour garder la trace de l'état de tri de chaque colonne
	let orderBy = writable([]);
	let groupedData = [];
	let pages = [];

	// FUNCTIONS

	onMount(async () => {
		await promiseQuery();
	});

	async function promiseQuery() {
		try {
			const searchKeysString = searchKey.join(',').replace('Famille', 'F_Famille').replace('Representant', 'F_COMPTET');
			const searchValuesString = searchValue.join(',');

			let queryData = await getQuery(
				'',
				'',
				'',
				'',
				searchKeysString,
				searchValuesString,
				$sortOrder,
				currentPage,
				rowsPerPage,
				$orderBy.join(',')
			);

			if (queryData.data.status === 404) {
				addToast(queryData.data.detail, 'bg-red-500');
				return;
			}

			data = queryData.data;
			if (queryData.pagination) {
				currentPage = queryData.pagination.PageNumber;
				totalPages = queryData.pagination.TotalPages;
				hasNext = queryData.pagination.HasNext;
				hasPrevious = queryData.pagination.HasPrevious;
				pages = Array.from({ length: totalPages }, (_, i) => i + 1);
			}
		} catch (error) {
			addToast(error, 'bg-red-500');
			console.log(error);
		}
	}

	function goToPage(page) {
		currentPage = page;
		promiseQuery();
	}

	// Fonction pour obtenir la valeur d'un objet par un chemin
	function getValueByPath(object, path) {
		return path.split('.').reduce((o, p) => o && o[p], object);
	}

	$: {
		// Appliquer le tri
		let sortedData = [...data];
		Object.entries($sortOrder).forEach(([key, order]) => {
			sortedData.sort((a, b) => {
				if (order === 'ASC') {
					return a[key] > b[key] ? 1 : -1;
				} else if (order === 'DESC') {
					return a[key] < b[key] ? 1 : -1;
				}
				return 0;
			});
		});

		// Grouper les données
		if (!groupByColumn) {
			groupedData = sortedData;
		} else {
			groupedData = sortedData.reduce((acc, row) => {
				const key = getValueByPath(row, groupByColumn); // Utiliser getValueByPath pour obtenir la valeur du groupe
				if (!acc[key]) acc[key] = [];
				acc[key].push(row);
				return acc;
			}, {});
		}
	}

	function handleColumnSelection(key) {
		selectedColumns.update((cols) =>
			cols.includes(key) ? cols.filter((col) => col !== key) : [...cols, key]
		);
	}

	// Fonction de recherche avec debounce
	const handleSearchInput = debounce((value, key) => {
		const index = searchKey.indexOf(key);

		if (value) {
			if (index === -1) {
				searchKey.push(key);
				searchValue.push(value);
			} else {
				searchValue[index] = value;
			}
		} else {
			if (index !== -1) {
				searchKey.splice(index, 1);
				searchValue.splice(index, 1);
			}
		}

		promiseQuery();
	}, 300); // Délai de 300 ms



	function handlePageChange(page) {
		currentPage = page;
		promiseQuery();
	}

	function handleSort(columnKey) {
		// Vérifier si le champ est un champ composé
		if (columnKey.includes('.')) {
			addToast('Ce champ ne peut pas être trié', 'bg-yellow-500');
			return;
		}

		let currentOrder = $sortOrder === 'ASC' ? 'DESC' : 'ASC';

		sortOrder.set(currentOrder);

		orderBy.update((order) => {
			if (!order.includes(columnKey)) {
				order.push(columnKey);
			}
			return order;
		});

		promiseQuery();
	}

	function handleRowsPerPageChange(event) {
		rowsPerPage = event.detail;
		currentPage = 1; // Reset to first page
		promiseQuery();
	}

	function handleRowClick(row) {
		dispatch('rowClick', row);
	}

	function toggleDropdown() {
		const dropdown = document.getElementById('dropdownSearch');
		dropdown.classList.toggle('hidden');
	}
</script>

{#await promiseQuery}
	<LoadingDots />
{:then}
	{#if data.length === 0}
		<p class="text-center text-lg text-gray-500">Aucun objet trouvé</p>
	{:else}
		<div class="p-4">
			<div class="flex items-end mb-4 space-x-4">
				<!-- Dropdown pour sélectionner les colonnes à afficher -->

				<div class="relative inline-block">
					<button
						id="dropdownSearchButton"
						data-dropdown-toggle="dropdownSearch"
						class="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-primary rounded-lg hover:bg-primaryDark"
						type="button"
						on:click={toggleDropdown}
						>Sélectionner les colonnes<svg
							class="w-2.5 h-2.5 ms-2.5"
							aria-hidden="true"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 10 6"
						>
							<path
								stroke="currentColor"
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								d="m1 1 4 4 4-4"
							/>
						</svg></button
					>

					<!-- Dropdown menu -->
					<div
						id="dropdownSearch"
						class="z-50 absolute mt-2 hidden rounded-lg shadow-lg min-w-52 bg-white ring-1 ring-black ring-opacity-5"
					>
						<ul
							class="h-40 pt-3 px-3 pb-3 overflow-y-auto text-base text-gray-700"
							aria-labelledby="dropdownSearchButton"
						>
							{#each columns as column}
								<li>
									<div class="flex justify-between px-4 py-2">
										<label for="column">
											{column.title}
										</label>
										<input
											type="checkbox"
											id="column"
											class="w-4 h-4 text-primary bg-gray-100 border-gray-300 rounded focus:ring-primary"
											checked={$selectedColumns.includes(column.key)}
											on:change={() => handleColumnSelection(column.key)}
										/>
									</div>
								</li>
							{/each}
						</ul>
					</div>
				</div>

				<!-- Sélecteur pour choisir le champs par lequel regrouper -->
				<Select
					width="w-40"
					options={groupByOptions}
					bind:value={groupByColumn}
					label="Regrouper par"
				/>

				<!-- Sélecteur pour le nombre de lignes par page -->
				<Select
					width="w-18"
					options={pageRowOptions}
					bind:value={rowsPerPage}
					on:change={handleRowsPerPageChange}
					label="Lignes par page"
				/>
			</div>

			<div class="overflow-auto custom-scrollbar rounded-lg shadow-md">
				<table class="min-w-full divide-y divide-gray-200">
					<thead class="bg-gray-200">
						<tr>
							{#each columns as column}
								{#if $selectedColumns.includes(column.key)}
									<th
										class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
										
									>
										<div class="flex items-center gap-4">
											<!-- svelte-ignore a11y-click-events-have-key-events -->
											<!-- svelte-ignore a11y-no-static-element-interactions -->
											<div class="flex items-center gap-2" on:click={() => handleSort(column.key)}>
												{column.title}
												{#if $orderBy.includes(column.key)}
													<span>{$sortOrder === 'ASC' ? '▲' : '▼'}</span>
												{/if}
											</div>
											<div class="mt-2">
												<input
													type="text"
													class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
													on:input={(e) => handleSearchInput(e.target.value, column.key)}
												/>
											</div>
										</div>
									</th>
								{/if}
							{/each}
						</tr>
					</thead>

					<tbody class="bg-white divide-y divide-gray-200">
						{#if Array.isArray(groupedData)}
							{#each groupedData as row}
								<tr class="hover:bg-gray-50" on:click={() => handleRowClick(row)}>
									{#each columns as column}
										{#if $selectedColumns.includes(column.key)}
											<td class="px-6 py-4 text-sm text-gray-700 whitespace-nowrap">
												<Tooltip text="Voir les détails">
													{getValueByPath(row, column.key)}
												</Tooltip>
											</td>
										{/if}
									{/each}
								</tr>
							{/each}
						{:else}
							{#each Object.keys(groupedData) as groupKey}
								<tr>
									<td colspan={columns.length} class="px-6 py-4 font-bold">{groupKey}</td>
								</tr>
								{#each groupedData[groupKey] as row}
									<Tooltip text="Voir les détails">
										<tr class="hover:bg-gray-50" on:click={() => handleRowClick(row)}>
											{#each columns as column}
												{#if $selectedColumns.includes(column.key)}
													<td class="px-6 py-4 text-sm text-gray-700 whitespace-nowrap">
														{getValueByPath(row, column.key)}
													</td>
												{/if}
											{/each}
										</tr>
									</Tooltip>
								{/each}
							{/each}
						{/if}
					</tbody>
				</table>
			</div>

			<nav class="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
				<div class="-mt-px flex w-0 flex-1">
					<button
						on:click={() => handlePageChange(currentPage - 1)}
						disabled={!hasPrevious}
						class="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
					>
						<svg
							class="mr-3 h-5 w-5 text-gray-400"
							viewBox="0 0 20 20"
							fill="currentColor"
							aria-hidden="true"
						>
							<path
								fill-rule="evenodd"
								d="M18 10a.75.75 0 01-.75.75H4.66l2.1 1.95a.75.75 0 11-1.02 1.1l-3.5-3.25a.75.75 0 010-1.1l3.5-3.25a.75.75 0 111.02 1.1l-2.1 1.95h12.59A.75.75 0 0118 10z"
								clip-rule="evenodd"
							/>
						</svg>
					</button>
				</div>
				<div class="hidden md:-mt-px md:flex">
					{#each pages as page}
						{#if page === currentPage}
							<button
								class="inline-flex items-center border-t-2 border-primary px-4 pt-4 text-sm font-medium text-primary"
								aria-current="page"
								on:click={() => goToPage(page)}
							>
								{page}
							</button>
						{:else if page === 1 || page === totalPages || (page >= currentPage - 1 && page <= currentPage + 1)}
							<button
								class="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
								on:click={() => goToPage(page)}
							>
								{page}
							</button>
						{:else if page === currentPage - 2 || page === currentPage + 2}
							<span
								class="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500"
							>
								...
							</span>
						{/if}
					{/each}
				</div>
				<div class="-mt-px flex w-0 flex-1 justify-end">
					<button
						on:click={() => handlePageChange(currentPage + 1)}
						disabled={!hasNext}
						class="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
					>
						<svg
							class="ml-3 h-5 w-5 text-gray-400"
							viewBox="0 0 20 20"
							fill="currentColor"
							aria-hidden="true"
						>
							<path
								fill-rule="evenodd"
								d="M2 10a.75.75 0 01.75-.75h12.59l-2.1-1.95a.75.75 0 111.02-1.1l3.5 3.25a.75.75 0 010 1.1l-3.5 3.25a.75.75 0 11-1.02-1.1l2.1-1.95H2.75A.75.75 0 012 10z"
								clip-rule="evenodd"
							/>
						</svg>
					</button>
				</div>
			</nav>
		</div>
	{/if}
{:catch error}
	<p class="text-center text-lg text-red-500">{error}</p>
{/await}

<style>
	.custom-scrollbar::-webkit-scrollbar {
		height: 8px;
	}
	.custom-scrollbar::-webkit-scrollbar-track {
		background: #f1f1f1;
	}
	.custom-scrollbar::-webkit-scrollbar-thumb {
		background: #888;
	}
	.custom-scrollbar::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
</style>
