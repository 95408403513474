<script>
	// Components
	import DataTable from '../../components/DataTable.svelte';
	
	// Client
	import { getClients } from '../../API/API_SAGE/ClientQueries';
	import TiersResponse from '../../API/API_SAGE/model/TiersResponse';
	import DocumentResponse from '../../API/API_SAGE/model/DocumentResponse';

	// Facture
	import { getFactureComptabilises } from '../../API/API_SAGE/DocVenteQueries';
	
	// Store
	import { drawerState, openDrawer, closeDrawer } from '../../store';

	// Svelte
	import { fly, fade } from 'svelte/transition';

	let columns = [
		{ key: 'CT_Num', title: 'CT_Num' },
		{ key: 'CT_Intitule', title: 'CT_Intitule' },
		{ key: 'Telecom.CT_Telephone', title: 'Telephone' }
	];

	function handleClick(message) {
		alert(message);
	}

	async function handleRowClick(event) {

		/** @type {TiersResponse} */
		const rowData = event.detail;
		let CA = await CalculCA(rowData);
		openDrawer({rowData, CA});
	}

	 /**
     * @param {TiersResponse} client
     * @returns {Promise<DocumentResponse[]>}
     */
	 async function getFacture(client) {
        const currentYear = new Date(Date.now()).getFullYear();

		try {
			await getFactureComptabilises("DO_TIERS,DO_DATE", `${client.CT_Num},${currentYear}`).then((res) => {
				return res.data;
			});
		} catch (error) {
			
		}
    }

    /**
     * @param {TiersResponse} client
     * @returns {number}
     */
    async function CalculCA(client) {
        const currentYear = new Date(Date.now()).getFullYear();
		let facturesComptabilises = [];
		try {
			facturesComptabilises = await getFactureComptabilises("DO_TIERS,DO_DATE", `${client.CT_Num},${currentYear}`).then((res) => {
				return res.data;
			});
			
		} catch (error) {
			
		}

        console.log(facturesComptabilises);
		

		if(facturesComptabilises.status === 404) return 0;
		
        let CA = 0;
        
		facturesComptabilises.forEach((facture) => {
            CA += facture.DO_TotalHT;
        });

        return CA;
    }
</script>

<DataTable
	onClick={handleClick}
	getQuery={getClients}
	{columns}
	rowsPerPage={10}
	on:rowClick={handleRowClick}
/>

{#if $drawerState.isOpen}
	<div
		class="fixed inset-0 z-10"
		aria-labelledby="slide-over-title"
		role="dialog"
		aria-modal="true"
	>
		<!-- Overlay background -->
		<div
			class="fixed inset-0 bg-gray-500 bg-opacity-75"
			aria-hidden="true"
			transition:fade={{ duration: 300 }}
			on:click={closeDrawer}
		></div>

		<div class="fixed inset-y-0 right-0 flex max-w-full pl-10">
			<div transition:fly={{ x: 500, duration: 600 }} class="w-screen max-w-md">
				<div
					class="h-screen-minus-header mt-16 flex flex-col overflow-y-scroll bg-white py-6 shadow-xl"
				>
					<div class="px-4 sm:px-6">
						<div class="flex items-start justify-between">
							<h2 class="text-base font-semibold leading-6 text-gray-900" id="slide-over-title">
								Détails du client
							</h2>
							<div class="ml-3 flex h-7 items-center">
								<button
									type="button"
									class="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
									on:click={closeDrawer}
								>
									<span class="absolute -inset-2.5"></span>
									<span class="sr-only">Close panel</span>
									<svg
										class="h-6 w-6"
										fill="none"
										viewBox="0 0 24 24"
										stroke-width="1.5"
										stroke="currentColor"
										aria-hidden="true"
									>
										<path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
									</svg>
								</button>
							</div>
						</div>
					</div>
					<div class="relative mt-6 flex-1 px-4 sm:px-6">
						<!-- Drawer content -->
						<p>CT_Num: {$drawerState.data.rowData.CT_Num}</p>
						<p>CT_Intitule: {$drawerState.data.rowData.CT_Intitule}</p>
						<p>CA: {$drawerState.data.CA}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
{/if}
