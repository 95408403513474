<script>
    export let title;
    export let items = [];
  


    $: isOpen = false;
  
    function toggleGroup() {
        isOpen = !isOpen;
    }
  </script>
  
  <div class="group-container">
    <button class="group-header flex justify-between items-center cursor-pointer" on:click={toggleGroup}>
      <div class="text-slate-400 hover:text-slate-300">{title}</div>

      
      <svg class="fill-slate-400 w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="-18.53 -18.53 222.40 222.40" transform={ isOpen ? 'rotate(90)' : ''}>
        <g>
            <g>
                <path d="M51.707,185.343c-2.741,0-5.493-1.044-7.593-3.149c-4.194-4.194-4.194-10.981,0-15.175 l74.352-74.347L44.114,18.32c-4.194-4.194-4.194-10.987,0-15.175c4.194-4.194,10.987-4.194,15.18,0l81.934,81.934 c4.194,4.194,4.194,10.987,0,15.175l-81.934,81.939C57.201,184.293,54.454,185.343,51.707,185.343z" />
            </g>
        </g>
    </svg>
    </button>
    <div class="group-items pl-4" class:hidden={!isOpen}>
      {#each items as item}
        <div class="py-2">
          <a href={item.href} class="text-red-100 hover:text-white " >{item.label}</a>
        </div>
      {/each}
    </div>
  </div>
  
  <style>
    .group-container {
      margin-bottom: 1rem;
    }
  
    .group-header {
      padding: 0.5rem 0;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  
    .group-items {
      transition: max-height 0.3s ease;
    }
  
    .group-items.hidden {
      max-height: 0;
      overflow: hidden;
    }

  </style>
  