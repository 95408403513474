<script>
	// Components
	import DataTable from '../../components/DataTable.svelte';

	import { getArticles } from '../../API/API_SAGE/ArticleQueries';

	let columns = [
		{ key: 'AR_Ref', title: 'AR_Ref' },
		{ key: 'AR_Design', title: 'AR_Design' },
		{ key: 'Famille.FA_Intitule', title: 'FA_Intitule' }
	];

	function handleClick(message) {
		alert(message);
	}
</script>

<DataTable onClick={handleClick} getQuery={getArticles} {columns} rowsPerPage={10} />
